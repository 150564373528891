import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import fetchHospitalListForHospitalReport from "../../actions/Report/fetchHospitalListForHospitalReport.action";
import {
  ServicePermissionEvent,
  ServicePermissionService,
} from "../../constants/servicePermission";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import DatePicker from "@mui/lab/DatePicker";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TextField from "@mui/material/TextField";
import RefreshIcon from "@mui/icons-material/Refresh";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListboxComponent from "../Utility/ListBoxComponents";
import { DateTimeToStrDate } from "../../functions/FuncDateTimes";
import "../../styles/refers/ReferNavbar.scss";
import { RootState } from "../../reducers";
import { shortHospName } from "../../functions/FuncPerjer";
import CircularProgress from "@mui/material/CircularProgress";
import { Autocomplete, Box } from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import { useParams } from "react-router-dom";

interface IReferNavbar {
  selectedDate: Date;
  hospCode: string;
  hospCodeData: {
    id: string;
    hospCode: string;
    hospName?: string;
  }[];
  referPoint: "all" | "er" | "ipd" | "opd";
  cid: string;
  title: string;
  getReferData(date: string, hospCode?: string): void;
  onHospCodeChange(): void;
  onReferPointChange(): void;
  getReferCidData(): void;
  onCidChange(): void;
  onDateChange(date: Dayjs, hospCode?: string): void;
  onNextDayChange(): void;
  onPreviousDayChange(): void;
}

export default function ReferNavbar({
  getReferData,
  hospCode,
  onHospCodeChange,
  hospCodeData,
  onReferPointChange,
  referPoint,
  cid,
  getReferCidData,
  onCidChange,
  selectedDate,
  onDateChange,
  onNextDayChange,
  onPreviousDayChange,
  title,
}: IReferNavbar) {
  const params = useParams<{ startDate: string }>();
  const { t } = useTranslation("referral");
  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);
  const { permissionRules, FetchingStatus } = appData;

  const dateChangedByButton = useRef(false);
  const calendarOpenFrom = useRef<string>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectableHospitals, setSelectableHospitals] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState<{
    hospCode: string;
    hospName: string;
  }>();
  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();

  // fetch hospital list for user who has permission to access other hospital report.
  useEffect(() => {
    if (ability) {
      if (
        ability.can(
          ServicePermissionEvent.READ,
          ServicePermissionService.ANY_HOSPITAL_REFER
        )
      ) {
        dispatch(
          fetchHospitalListForHospitalReport({
            idToken: appData.idToken,
            limit: 1000,
          })
        );
        setSelectableHospitals(true);
      }
    }
  }, [ability]);

  useEffect(() => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));
    }
  }, [permissionRules]);

  const renderMobileFilter = () => {
    return (
      <>
        <IconButton
          className="
          lg:!hidden"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          className="w-full"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem>
            <FormControl className="flex-auto">
              <Select
                fullWidth
                className="bg-transparent"
                labelId="hospcode-select"
                id="hospcode-select"
                displayEmpty
                value={hospCode}
                onChange={onHospCodeChange}
              >
                <MenuItem value="all">{t("refer.hospital")}</MenuItem>
                {hospCodeData.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.hospName ? item.hospName : item.hospCode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MenuItem>

          <MenuItem>
            <FormControl className="flex-auto">
              <Select
                displayEmpty
                className="bg-transparent"
                value={referPoint}
                onChange={onReferPointChange}
              >
                <MenuItem value="all">{t("refer.encounter")}</MenuItem>
                <MenuItem value="er">- ER -</MenuItem>
                <MenuItem value="opd">- OPD -</MenuItem>
                <MenuItem value="ipd">- IPD -</MenuItem>
              </Select>
            </FormControl>
          </MenuItem>

          <MenuItem>
            <form onSubmit={getReferCidData}>
              <TextField
                className="flex-auto bg-gray-100"
                name="cid"
                value={cid}
                onChange={onCidChange}
                placeholder={t("refer.searchBar")}
                variant="outlined"
                sx={{
                  "& input:-webkit-autofill": {
                    px: 1,
                    py: 1,
                    WebkitBoxShadow: "0 0 0px 1000px #f3f4f6 inset",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </MenuItem>
        </Menu>
      </>
    );
  };

  const renderLaptopFilter = () => {
    return (
      <div
        className="gap-x-4 hidden
        lg:flex
        xl:w-4/6"
      >
        <FormControl className="flex-auto w-36">
          <Select
            className="bg-transparent"
            labelId="hospcode-select"
            id="hospcode-select"
            displayEmpty
            value={hospCode}
            onChange={onHospCodeChange}
          >
            <MenuItem value="all">{t("refer.hospital")}</MenuItem>
            {hospCodeData.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.hospName ? item.hospName : item.hospCode}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className="flex-auto w-28">
          <Select
            displayEmpty
            className="bg-transparent"
            value={referPoint}
            onChange={onReferPointChange}
          >
            <MenuItem value="all">{t("refer.encounter")}</MenuItem>
            <MenuItem value="er">- ER -</MenuItem>
            <MenuItem value="opd">- OPD -</MenuItem>
            <MenuItem value="ipd">- IPD -</MenuItem>
          </Select>
        </FormControl>
        <form onSubmit={getReferCidData}>
          <TextField
            className="flex-auto w-80 bg-gray-100 rounded-full"
            name="cid"
            value={cid}
            onChange={onCidChange}
            placeholder={t("refer.searchBar")}
            variant="outlined"
            sx={{
              "& input:-webkit-autofill": {
                px: 1,
                py: 1,
                WebkitBoxShadow: "0 0 0px 1000px #f3f4f6 inset",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </form>
      </div>
    );
  };

  return (
    <AppBar id="refer-navbar" position="static" className="mt-16 !bg-white">
      <Toolbar variant="dense" className="py-4">
        <div className="flex flex-grow items-center">
          <h5
            className="text-sky-500 mr-2
            md:mr-8 md:text-xl"
          >
            {title === "Refer In" ? t("refer.referIn") : t("refer.referOut")}
          </h5>
          {selectableHospitals && appData.hospitalList.docs && (
            <div id="hospital-selector" className="mr-2 flex-1">
              <Autocomplete
                disableListWrap
                loading={appData.hospitalList.length <= 0}
                ListboxComponent={ListboxComponent}
                options={appData.hospitalList.docs}
                onChange={(option, value) => {
                  if (
                    value &&
                    Object.prototype.hasOwnProperty.call(value, "hospCode")
                  ) {
                    setSelectedHospital(value);
                  }
                }}
                getOptionLabel={(option: {
                  hospCode: string;
                  hospName: string;
                }) => `${option.hospCode} : ${shortHospName(option.hospName)}`}
                noOptionsText={"Can not find hospital code or hospital name"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    placeholder="เลือกโรงพยาบาล"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {appData.hospitalList.length <= 0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    className="truncate cursor-pointer pl-2 pt-2 hover:bg-slate-50 "
                    key={option.hospCode}
                  >
                    {`${option.hospCode} : ${shortHospName(option.hospName)}`}
                  </li>
                )}
              />
            </div>
          )}

          <DatePicker
            views={["day"]}
            value={selectedDate}
            maxDate={dayjs()}
            inputFormat="DD/MM/YYYY"
            onChange={(date) => {
              if (!date) return;

              const isDateDifferent = !dayjs(calendarOpenFrom.current).isSame(
                dayjs(date),
                "day"
              );

              if (
                !dateChangedByButton.current ||
                (dateChangedByButton.current && isDateDifferent)
              ) {
                onDateChange(date, selectedHospital?.hospCode);
              }

              dateChangedByButton.current = false;
            }}
            onOpen={() => {
              calendarOpenFrom.current = params.startDate;
            }}
            renderInput={(params) => (
              <TextField {...params} className="!rounded-full" />
            )}
            InputProps={{
              endAdornment: <CalendarTodayIcon className="text-gray-500" />,
            }}
            components={{
              LeftArrowButton: (props) => (
                <Box>
                  <IconButton {...props} disabled={FetchingStatus}>
                    <KeyboardDoubleArrowLeft />
                  </IconButton>

                  <IconButton
                    {...props}
                    onClick={() => {
                      dateChangedByButton.current = true;
                      onPreviousDayChange();
                    }}
                    disabled={FetchingStatus}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                </Box>
              ),
              RightArrowButton: (props) => (
                <Box>
                  <IconButton
                    {...props}
                    onClick={() => {
                      dateChangedByButton.current = true;
                      onNextDayChange();
                    }}
                    disabled={FetchingStatus}
                  >
                    <KeyboardArrowRight />
                  </IconButton>

                  <IconButton {...props} disabled={FetchingStatus}>
                    <KeyboardDoubleArrowRight />
                  </IconButton>
                </Box>
              ),
            }}
            componentsProps={{
              leftArrowButton: { "aria-label": "Previous day" },
              rightArrowButton: { "aria-label": "Next day" },
            }}
          />

          <IconButton
            className="icon-button avatar-button"
            title="Refresh"
            onClick={() => {
              getReferData(
                selectedDate ? DateTimeToStrDate(selectedDate) : selectedDate,
                selectedHospital?.hospCode
              );
            }}
            size="large"
          >
            <RefreshIcon />
          </IconButton>
        </div>

        {!selectableHospitals && renderMobileFilter()}
        {!selectableHospitals && renderLaptopFilter()}
      </Toolbar>
    </AppBar>
  );
}
